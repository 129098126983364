$themes: (
  light: (
    colorBackground: white,
    colorBackgroundBody: #f2f4f7,
    templateColor: #0e4768,
    blackColor:black, 
    colorTextAdditional: #646777,
    // logoImg: url(../shared/img/logo/inmedtoryLogo.png),
    colorHover: #fafbfe,
    colorFolderHover: #f0eeee,
    colorBorder: #eff1f5,
    colorIcon: #dddddd,
    imgInvert: invert(0%),
    colorFieldsBorder: #f2f4f7,
    colorBubble: rgba(242, 244, 247, 0.65),
    colorBubbleActive: rgba(234, 238, 255, 0.6),
    colorScrollbar: #B4BFD0,
    colorFitness: #646777,
  ),
  dark: (
    colorBackground: #232329,
    colorBackgroundBody: #2a2a31,
    colorText: #dddddd,
    colorTextAdditional: #999999,
    // logoImg: url(../shared/img/logo/logo_dark.svg),
    colorHover: #38373f,
    colorFolderHover: #ffffff1A,
    colorBorder: #333246,
    colorIcon: #605f7b,
    imgInvert: invert(100%),
    colorFieldsBorder: #33333a,
    colorBubble: rgba(68, 79, 97, 0.65),
    colorBubbleActive: rgba(92, 104, 156, 0.6),
    colorScrollbar: #606071,
    colorFitness: #ffffff
  )
);

@mixin themify($themes) {
  @each $theme, $map in $themes {
    .theme-#{$theme} & {
      $theme-map: () !global;
      @each $key, $submap in $map {
        $value: map-get(map-get($themes, $theme), '#{$key}');
        $theme-map: map-merge($theme-map, ($key: $value)) !global;
      }
      @content;
      $theme-map: null !global;
    }
  }
}

@function themed($key) {
  @return map-get($theme-map, $key);
}
$color-template-light:#e6f6ff;
$color-template: rgb(60, 97, 119);
$color-dark-background:#0e4768;
$color-black:black;
$color-white:white;
$table-header-bg-color:rgb(60, 97, 119);//tables by tej css
$table-header-text-color:rgb(255, 255, 255);

// $sidebar-Hover:#538bac;
$sidebar-Hover:rgba(28, 160, 181, 0.29);
$color-accent: #0e4768;
$color-accent-hover: darken($color-accent, 10%);
$color-additional: #999999;
$color-additional-hover: darken($color-additional, 10%);

$color-template-hover: darken($color-template, 10%);

$color-violet: #c88ffa;

$color-red: #ff4861;
$color-red-hover: darken($color-red, 10%);

$color-blue-hover: darken($color-template, 10%);

$color-template-hover: darken($color-template, 10%);

$color-gray: #787985;
$color-light-gray: #d8dfe9;
$color-yellow: #f6da6e;
$color-yellow-hover: darken($color-yellow, 10%);

$color-template-hover: darken($color-template, 10%);
$color-green: #22c2ad;
$color-green-hover: darken($color-green, 10%);
$color-template-hover: darken($color-template, 10%);
$button-bg-color: #3c6177;
$button-text-color: #ffffff;
$navbar-text-color: #3c6177;
$font-type: inherit, 'Roboto', sans-serif;
$button-hover-color: darken($button-bg-color, 10%);
